import { Button, Heading, Link, Picture } from '@troon/ui';
import { Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { gql } from '../../graphql';
import { courseTypeString } from './type-strings';
import type { FacilityListCardFragment } from '../../graphql';

type Props = { facility: FacilityListCardFragment };

export function FacilityListCard(props: Props) {
	return (
		<div class="group relative flex items-start gap-4 rounded border border-neutral p-4 hover:bg-neutral-100 motion-safe:transition-colors motion-safe:duration-200 md:items-center md:gap-6">
			<Show when={props.facility.metadata?.hero?.url}>
				{(url) => (
					<div class="shrink-0 overflow-hidden rounded">
						<Picture
							src={url()}
							sizes={[[200, 150]]}
							class="size-24 object-cover group-hover:scale-110 motion-safe:transition-transform motion-safe:duration-200 md:size-full "
							alt=""
							loading="lazy"
						/>
					</div>
				)}
			</Show>
			<div class="flex grow flex-col gap-1.5 md:gap-2">
				<Heading as="h3" size="h5">
					<Link href={`/course/${props.facility.slug}`} class="after:absolute after:inset-0">
						{props.facility.name}
					</Link>
				</Heading>
				<span class="order-first text-sm uppercase text-neutral-800">{courseTypeString[props.facility.type]}</span>
				<Show when={props.facility.metadata?.address}>
					{(address) => (
						<address aria-label={`Address for ${props.facility.name}`} class="text-sm not-italic">
							<Show when={address().street}>
								<span itemprop="streetAddress">{address().street}</span>,{' '}
							</Show>
							<Show when={address().city}>
								<span itemprop="addressLocality">{address().city}</span>,{' '}
							</Show>
							<Show when={address().state}>
								<span itemprop="addressRegion">{address().state}</span>{' '}
							</Show>
							<Show when={address().postalCode}>
								<span itemprop="postalCode">{address().postalCode}</span>
							</Show>
						</address>
					)}
				</Show>
			</div>
			<div class="hidden flex-row gap-3 xl:flex">
				<Button
					as={Link}
					appearance="secondary"
					href={`/course/${props.facility.slug}`}
					class="relative z-10 w-fit grow-0"
				>
					<Icon name="info" />
					<span class="sr-only">View {props.facility.name}</span>
				</Button>
			</div>
		</div>
	);
}

gql(`
fragment FacilityListCard on Facility {
	slug
	name
	type
	isAvailable
	metadata {
		address {
			...Address
		}
		hero {
			url
		}
	}
}
`);
